import { ERole, type ILoginPayload, type IRegisterUserPayload, type ITokens } from '@/types'
import { authService } from '@/views/auth/auth.service'
import { localStorageKeys } from '@/constants'
import { routesNames } from '@/router'
import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import { jwtDecode } from 'jwt-decode'

export const useAuthStore = defineStore('authStore', () => {
  const router = useRouter()

  const tokens = ref<ITokens>({
    access_token: localStorage.getItem(localStorageKeys.AUTH_ACCESS_TOKEN) || '',
    refresh_token: localStorage.getItem(localStorageKeys.AUTH_REFRESH_TOKEN) || ''
  })

  const role = computed(() => {
    if (!tokens.value.access_token) return ERole.USER
    const decoded = jwtDecode<{is_admin_logged_in: boolean}>(tokens.value.access_token)
    return decoded.is_admin_logged_in ? ERole.LAWYER : ERole.USER
  })

  function setToken (_tokens: ITokens) {
    tokens.value = _tokens
    localStorage.setItem(localStorageKeys.AUTH_ACCESS_TOKEN, _tokens.access_token)
    localStorage.setItem(localStorageKeys.AUTH_REFRESH_TOKEN, _tokens.refresh_token)
  }

  async function registerUser (_payload: IRegisterUserPayload) {
    const res = await authService.register(_payload)

    setToken(res.tokens)

    await router.push({ name: routesNames.main })
  }

  async function loginUser (_payload: ILoginPayload | string) {
    let res: ITokens
    if (typeof _payload === 'string') {
      res = await authService.loginAs(_payload)
    } else {
      res = await authService.login(_payload)
    }

    setToken(res)

    await router.push({ name: routesNames.main })
  }

  async function logout () {
    tokens.value = { access_token: '', refresh_token: '' }
    localStorage.removeItem(localStorageKeys.AUTH_ACCESS_TOKEN)
    localStorage.removeItem(localStorageKeys.AUTH_REFRESH_TOKEN)

    await router.push({ name: routesNames.login })
  }

  async function resetPassword <T extends {email: string} | {token: string}> (_payload: T) {
    if ('email' in _payload) {
      await authService.resetPasswordSendEmail(_payload.email)
    } else {
      return (await authService.resetPasswordConfirm(_payload.token)).password
    }
  }

  async function refreshAccessToken (instance: AxiosInstance, originalRequest: InternalAxiosRequestConfig) {
    try {
      tokens.value.access_token = ''
      const refreshedTokens = await authService.refreshAccessToken(tokens.value.refresh_token)
      setToken(refreshedTokens)
      await nextTick()
      return instance(originalRequest)
    } catch (error) {
      await logout()
      return Promise.reject(error)
    }
  }

  return {
    tokens,
    role,
    loginUser,
    registerUser,
    resetPassword,
    refreshAccessToken,
    logout
  }
})
