import { http } from '@/http'
import type { ILoginPayload, IRegisterUserPayload, ITokens } from '@/types'

class AuthService {
  register (_payload: IRegisterUserPayload) {
    type TResponse = IRegisterUserPayload & { tokens: ITokens }
    const offsetInMinutes = new Date().getTimezoneOffset()

    const utc = -offsetInMinutes / 60

    return http.post<TResponse>('/signup/', { ..._payload, utc })
  }

  login (_payload: ILoginPayload) {
    return http.post<ITokens>('/signin/', _payload)
  }

  loginAs (_token: string) {
    return http.post<ITokens>('/exchange_token/', { token: _token })
  }

  resetPasswordSendEmail (_username: string) {
    return http.post('/reset-password-mail/', { username: _username })
  }

  resetPasswordConfirm (_token: string) {
    return http.post<{password: string}>('/reset-password-finish/', { token: _token })
  }

  refreshAccessToken (_refreshToken: string) {
    return http.post<ITokens>('/refresh-token/', { refresh_token: _refreshToken })
  }
}
export const authService = new AuthService()
