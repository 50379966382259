import type { RouteRecordRaw } from 'vue-router'

const contractRouteNames = {
  contract: 'contract'
}

const contractRoutes: RouteRecordRaw = {
  path: 'contract',
  name: contractRouteNames.contract,
  component: () => import('@/views/contract/Contract.vue')
}

export { contractRouteNames, contractRoutes }
