import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { useAuthStore } from '@/stores/shared'

const responseInterceptor = (response: AxiosResponse): Promise<AxiosResponse> => {
  return response.data
}

const errorInterceptor = async (error: AxiosError, instance: AxiosInstance) => {
  const { refreshAccessToken, logout } = useAuthStore()

  if (error.response?.status === 401) {
    const originalRequest = error.config
    if (originalRequest?.url?.includes('refresh-token')) {
      await logout()
    } else {
      if (originalRequest) {
        return await refreshAccessToken(instance, originalRequest)
      }
    }
  }

  return Promise.reject(error)
}

export {
  responseInterceptor,
  errorInterceptor
}
