import type { RouteRecordRaw } from 'vue-router'

const tariffRouteNames = {
  tariff: 'tariff'
}

const tariffRoutes: RouteRecordRaw = {
  path: 'tariff',
  name: tariffRouteNames.tariff,
  component: () => import('@/views/tariff/Tariff.vue')
}

export { tariffRouteNames, tariffRoutes }
