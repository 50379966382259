import type { TNullable } from '@/types/shared'

export const enum EFileType {
  PDF = 'PDF',
  DOC = 'DOC'
}

export const enum EDocumentsStatus {
  PENDING = 1,
  SENT,
  REJECTED,
  COURT
}

export interface IMainInfoTariffPlan {
  title: string
  amount: number
}

export interface IMainInfoTariff {
  id: number
  name: string
  total_price: number
  payment_plan_records: IMainInfoTariffPlan[]
  mail_price: number
  licence_price: number
  deposit_price: number
  compensation_price: number
}

export interface IMainInfoSurveyBanners {
  banner_1: boolean
  banner_1_fields: object
  banner_2: boolean
  banner_2_fields: object
}

interface IInstallment {
  amount: number
  creditors_count: number
  end_date: string
  is_finished: boolean
  next_pay_amount: number
  next_pay_date: string
  payment_amounts: number[]
  payment_dates: string[]
  records: number[]
  should_pay: boolean
  start_date: string
}

export interface IMainInfo {
  id: number
  name: string
  email: string
  phone: string
  isk: TNullable<string>
  creditors: TNullable<string>
  properties: TNullable<string>
  generation_time: TNullable<string>
  triggers_file: TNullable<string>
  tariff: TNullable<IMainInfoTariff>
  is_tariff_paid: boolean
  installments: IInstallment[]
  calcs: TNullable<{
    '300': number
    '301': number
    '302': number
  }>
  banners: TNullable<{
    banner_1: boolean
    banner_1_fields: TNullable<{
      months: string
      amount: string
    }>
    banner_2: boolean
    banner_2_fields: TNullable<{
      amount_first: string
      amount_second: string
      date: string
      properties: string[]
    }>
  }>
  documents_status: EDocumentsStatus
}

export interface IMainInfoDocument {
  name: string
  generationDate: string
  link: string
  extension: string
  fileType: EFileType
}
