import type { RouteRecordRaw } from 'vue-router'

const tutorialRouteNames = {
  tutorial: 'tutorial'
}

const tutorialRoutes: RouteRecordRaw = {
  path: 'tutorial',
  name: tutorialRouteNames.tutorial,
  component: () => import('@/views/tutorial/Tutorial.vue')
}

export { tutorialRouteNames, tutorialRoutes }
