import { authRouteNames } from '@/views/auth/auth.routes'
import { mainRouteNames } from '@/views/main/main.routes'
import { surveyRouteNames } from '@/views/survey/survey.routes'
import { documentsRouteNames } from '@/views/documents/documents.routes'
import { tariffRouteNames } from '@/views/tariff/tariff.routes'
import { tutorialRouteNames } from '@/views/tutorial/tutorial.routes'
import { contractRouteNames } from '@/views/contract/contract.routes'

export const routesNames = {
  rootPage: 'rootPage',
  notFound: 'notFound',
  ...authRouteNames,
  ...mainRouteNames,
  ...surveyRouteNames,
  ...documentsRouteNames,
  ...tariffRouteNames,
  ...tutorialRouteNames,
  ...contractRouteNames
}
