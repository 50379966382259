import type { TNullable } from '@/types/shared'

export const enum ESurveySteps {
  NO_ACTIVE_SURVEY = 'NO_ACTIVE_SURVEY',
  STEP_ONE = 'STEP_ONE',
  STEP_TWO = 'STEP_TWO',
  STEP_THREE = 'STEP_THREE',
  STEP_FOUR = 'STEP_FOUR',
  STEP_FIVE = 'STEP_FIVE',
  STEP_SIX = 'STEP_SIX',
  STEP_SEVEN = 'STEP_SEVEN',
  STEP_EIGHT = 'STEP_EIGHT',
  STEP_NINE = 'STEP_NINE',
  STEP_TEN = 'STEP_TEN',
  STEP_ELEVEN = 'STEP_ELEVEN',
  STEP_TWELVE = 'STEP_TWELVE',
  STEP_THIRTEEN = 'STEP_THIRTEEN',
  STEP_FOURTEEN = 'STEP_FOURTEEN',
  STEP_FIFTEEN = 'STEP_FIFTEEN',
  STEP_SIXTEEN = 'STEP_SIXTEEN',
  STEP_SEVENTEEN = 'STEP_SEVENTEEN',
  STEP_EIGHTEEN = 'STEP_EIGHTEEN',
  STEP_NINETEEN = 'STEP_NINETEEN',
  STEP_TWENTY = 'STEP_TWENTY',
  STEP_TWENTY_ONE = 'STEP_TWENTY_ONE',
  STEP_TWENTY_TWO = 'STEP_TWENTY_TWO',
  STEP_TWENTY_THREE = 'STEP_TWENTY_THREE',
  STEP_TWENTY_FOUR = 'STEP_TWENTY_FOUR',
  STEP_TWENTY_FIVE = 'STEP_TWENTY_FIVE',
  STEP_TWENTY_SIX = 'STEP_TWENTY_SIX',
  STEP_FINAL = 'STEP_FINAL',
  HAS_FILLED_SURVEY = 'HAS_FILLED_SURVEY'
}

export type TNotFillableSteps = ESurveySteps.NO_ACTIVE_SURVEY | ESurveySteps.STEP_FINAL | ESurveySteps.HAS_FILLED_SURVEY
export type TOnlyFillableSteps = Exclude<ESurveySteps, TNotFillableSteps>

export const enum EWorkStatus {
  HAS_WORK = 'Трудоустроен',
  NO_WORK = 'Безработный'
}

export const enum ECreditor {
  BANK = 'Банк',
  MICRO_FINANCE_ORGANIZATION = 'Микрофинансовая организация',
  FIZ = 'Физическое лицо',
  FEDERAL_INSPECTION = 'Федеральная налоговая инспекция',
  JKH = 'ЖКХ',
  OTHER_FIZ = 'Иное юридическое лицо',
  FINES = 'Штрафы'
}

export const enum ETaxDebt {
  FOP = 'Деятельность индивидуального предпринимателя',
  FIZ = 'Деятельность физического лица'
}

export const enum ETaxName {
  TRANSPORT = 'Транспортный',
  LAND = 'Земельный',
  PROPERTY = 'Налог на имущество',
  NDFL_PROFIT = 'Налог на доход 2НДФЛ',
  OTHER = 'Иное',
  NDS = 'НДС',
  NDFL = 'НДФЛ',
  EXCISE = 'Акцизы',
  EXTRACTION = 'Налог на добычу полезных ископаемых',
  WATER = 'Водный налог',
  TRADE = 'Торговый сбор',
  FAUNA = 'Сбор за пользование объектами животного мира',
  SUBOIL = 'Регулярный платеж за пользование недрами',
  BIOLOGICAL = 'Сбор за пользование объектами водных биологических ресурсов',
  USN = 'УСН',
  UNIFIED = 'Единый сельско-хозяйственный налог',
  PATENT = 'Патент',
  AUTOMATIC = 'Автоматизированная упрощенная система налогообложения',
  PENSION = 'Пенсионный фонд',
  SOCIAL = 'Фонд Социального страхования',
  TOTAL_TAX_DEBT_AMOUNT = 'Общая сумма долга по налогам',
  TOTAL_TAX_FINES_AND_PENALTIES_AMOUNT = 'Общая сумма штрафов и пеней по налогам'
}

export const enum EDealPlace {
  HAS = 'У меня на руках',
  LOST = 'Утерян'
}

export const enum EDebtType {
  MAIN = 'Займ',
  MICRO = 'Микрозайм'
}

export const enum ECredit {
  FOR_BUSINESS = 'Целевой кредит на открытие/развитие бизнеса',
  OVERDRAFT = 'Овердрафт',
  CREDIT_LINE = 'Кредитная линия',
  LIZING = 'Лизинг',
  FACTORING = 'Факторинг',
  ACREDIT = 'Аккредитив',
  CONSUMER = 'Потребительский',
  CREDIT_CARD = 'Кредитная карта',
  AUTO_CREDIT = 'Автокредит',
  MORTGAGE = 'Ипотека'
}

export const enum EDealCopy {
  BANK_SITE = 'Мобильное приложение/сайт Банка',
  BANK = 'Отделение Банка',
  CREDIT_HISTORY = 'Обращение через портал “Госуслуги” в Бюро кредитных историй',
  ENFORCEMENT_OR_ARREST_ORDER = 'Постановление о возбуждении исполнительного производства или наложении ареста'
}

export const enum EGender {
  MALE = 'Мужчина',
  FEMALE = 'Женщина'
}

export const enum EBasisInRussia {
  RVP = 'РВП',
  VNJ = 'ВНЖ',
  VISA = 'Виза',
  CARD = 'Миграционная карта'
}

export interface IStepOnePayload {
  lastname: string
  name: string
  surname: string
  is_fio_changed: boolean
  is_name_changed: boolean
  is_lastname_changed: boolean
  is_surname_changed: boolean
  prev_name: string[]
  prev_lastname: string[]
  prev_surname: string[]
  is_rf_citizenship: boolean
  in_rf_justification: EBasisInRussia | '-'
  gender: EGender
  dob: string
  birth_place: string
  document_series: string
  document_date: string
  document_given_by: string
  code: string
  address: string
  region: string
  city: string
  settlement: string
  street: string
  house: string
  block: string
  apartment: string
  is_temp_address_factual: boolean
  factual_address: string
  factual_region: string
  factual_city: string
  factual_settlement: string
  factual_street: string
  factual_house: string
  factual_block: string
  factual_apartment: string
  is_inn: boolean
  inn: string
  is_snils: boolean
  snils: string
  is_ilc: boolean
}

export const enum EFOPOpenedStatus {
  OPEN = 'Открыто сейчас',
  CLOSED_IN_LAST_3_YEARS = 'Закрыто в течение последних 3 лет',
  CLOSED_MORE_THAN_3_YEARS = 'Закрыто более 3 лет назад',
  NEVER = 'Никогда не было',
}

export const enum ESelfEmployedIncomeWay {
  CASH = 'Наличные',
  MY_CARD = 'На свою банковскую карту',
  SMOTHER_CARD = 'На чужую банковскую карту'
}

interface IStepTwoPayload {
  work_status: EWorkStatus
  is_in_czn: boolean
  jobs: {
    name: string
    position: string
    avg_salary: number
  }[]
  individual_worker_status: EFOPOpenedStatus | '-'
  is_self_employed: boolean
  self_employed_income_way: ESelfEmployedIncomeWay | '-'
  avg_monthly_salary: number
  is_bills_for_fns: boolean
}

export const enum EBankruptcypPocedure {
  SALE_OF_PROPERTY = 'Реализация имущества',
  RESTRUCTURING = 'Реструктуризация'
}

export const enum EDealInFSSPDuration {
  MORE_THAN_1_YEAR_AGO = 'Более 1 года назад',
  MORE_THAN_7_YEAR_AGO = 'Более 7 лет назад',
  LESS_THAN_1_YEAR_AGO = 'Менее 1 года назад',
}

export const enum ECourtCaseType {
  DIVORCE = 'Развод',
  DEBT_COLLECTION = 'Взыскание задолженности',
  PROPERTY_DIVISION = 'Раздел имущества',
  OTHER = 'Иное судебное делопроизводство'
}

export const enum EDisabilityGroup {
  ONE = '1 группа',
  TWO = '2 группа',
  THREE = '3 группа'
}

export const enum ECriminalType {
  CASE158 = 'ст. 158.1 УК РФ (Мелкое хищение)',
  CASE159 = 'ст. 159 УК РФ (Мошенничество)',
  CASE167 = 'ст. 167 УК РФ (Умышленные уничтожение или повреждение имущества)',
  CASE197 = 'ст. 197 (Фиктивное банкротство)',
  OTHER = 'Иная статья'
}

export const enum EDebtStatus {
  DONE = 'Да, снята',
  PAID = 'Нет, не снята, но погашена',
  IN_PROGRESS = 'Нет, не снята и не погашена'
}

interface IStepThreePayload {
  is_bankruptcy_passed_last_5_years: boolean
  bankruptcy_procedure: EBankruptcypPocedure | '-'
  is_plan_for_restruct: boolean
  is_deal_in_fccp: boolean
  deal_in_fccp_duration: EDealInFSSPDuration | '-'
  is_fccp_banned_departure: boolean
  is_court_case: boolean
  court_cases: (ECourtCaseType | '-')[]
  is_retired: boolean
  avg_retire_fee: number
  is_retire_fee_only: boolean
  is_fee_for_child: boolean
  is_child_fee_only: boolean
  is_allowance: boolean
  allowance_avg_fee: number
  is_disabled_person: boolean
  disability_group: EDisabilityGroup | '-'
  is_criminal: boolean
  criminal_state_number: ECriminalType | '-'
  criminal_state_other: string
  is_debt_for_159: boolean
  debt_for_159: number
  debt_for_other: EDebtStatus | '-'
  is_more_than_500k_last_3_years: boolean
  enforcement_proceedings: boolean
  is_deal_in_fccp_new: boolean
}

export const enum EKidResidenceStatus {
  TOGETHER = 'Совместно',
  APART = 'Раздельно'
}
export const enum EGunType {
  SPORT = 'спортивное',
  GAS = 'газовое',
  TRAUMATIC = 'травматическое',
  HUNTING = 'охотничье'
}

interface IStepFourPayload {
  is_in_marriage: boolean
  is_divorced: boolean
  is_not_married: boolean
  is_widow: boolean
  partner_name: string
  partner_dob: string
  divorces: {partner_name: string; partner_dob: string}[]
  dead_partners: {partner_name: string; partner_dob: string}[]
  kids: {name: string; dob: string; status: EKidResidenceStatus}[]
  is_kid_before_18: boolean
}

export interface IStepFIvePayload {
  is_any_credit: boolean
  credits: {
    type: ECreditor
    name: string
    how_tax_debt: ETaxDebt | '-'
    tax_name: ETaxName | '-'
    debt_sum: number
    tax_sum: number
    penalty_sum: number
    inn: string
    date: string
    deal_sum: number
    deal_place: EDealPlace | '-'
    debt_type: EDebtType | '-'
    is_debt_while_ip: boolean
    credit_type: ECredit | '-'
    deal_number: string
    deal_copy: EDealCopy | '-'
    address: string
    full_inn_address: string
  }[]
}

export interface IStepSixPayload {
  is_aliminet_owner: boolean
  receivers: {sum: number; name: string; address: string}[]
}

export const enum EPledgeOwner {
  ENTITY = 'Юридическое лицо',
  FIZ = 'Физическое лицо'
}

interface IStepSevenPayload {
  is_obligation_for_health_damage: boolean
  health_obligations: {
    fullname: string
    address: string
    debt: number
  }[]
  is_obligation_for_property_damage: boolean
  property_obligations: {
    obligation_taker: EPledgeOwner
    inn_or_name: string
    debt: number
    fullname: string
    address: string
    full_inn_address: string
    org_address: string
  }[]
  is_obligation_for_moral_damage: boolean
  moral_obligations: {
    fullname: string
    address: string
    debt: number
  }[]
  is_obligation_for_subsid: boolean
  subsid_obligations: {
    inn_or_name: string
    debt: number
    full_inn_address: string
    org_address: string
  }[]
}

export const enum ELandVehicleForUtil {
  CAR = 'Легковой автомобиль',
  FREIGHT_CAR = 'Грузовой автомобиль',
  BUS = 'Автобус',
  MOTOR_VEHICLE = 'Мототранспортное средство с объемом двигателя более 50 куб.см',
  TRACTOR = 'Трактор',
  TRACKED_TRANSPORT = 'Гусеничный транспорт',
  OTHER = 'Иное наземное транспортное средство'
}

export const enum EVehicleForUtilReason {
  SELL = 'Продажа',
  DISPOSAL = 'Утилизация',
  AUTO_ANALYSIS = 'Авторазбор',
  STEALING = 'Угон',
  OTHER = 'Утрата на других основаниях'
}

export const enum ETransportStatus {
  HAS = 'В наличии',
  LOST = 'Утерян/угон'
}

export const enum EVehicleRelation {
  EMPLOYMENT_CONTRACT_WITH_AN_INDIVIDUAL = 'Трудовой договор с физическим лицом',
  OFFER_AGREEMENT_AS_AN_INDIVIDUAL_ENTREPRENEUR = 'Договор-оферта как индивидуальный предприниматель',
  CONTRACT_OFFER_AS_A_SELF_EMPLOYED_PERSON = 'Договор-оферта как самозанятый',
  AGENCY_AGREEMENT_AS_AN_INDIVIDUAL_ENTREPRENEUR = 'Агентский договор как индивидуальный предприниматель',
  AGENCY_AGREEMENT_AS_SELF_EMPLOYED = 'Агентский договор как самозанятый',
  SERVICE_AGREEMENT = 'Договор оказания услуг',
  WORK_WITHOUT_A_CONTRACT = 'Работа без договора (работаю на себя)'
}

export const enum ELandVehicleLicense {
  PERMISSION_TO_TRANSPORT_PEOPLE_AND_LUGGAGE = 'разрешение на перевозку людей и багажа',
  LICENSE_FOR_TRANSPORTING_PASSENGERS_BY_BUS = 'лицензия на транспортные перевозки пассажиров автобусами',
  OTHER = 'иное (указать что)'
}

export const enum ELandVehicleUsage {
  MYSELF = 'для себя',
  PARTNER = 'для супруги(а)',
  KIDS = 'для детей',
  PARENTS = 'для родителей',
  OTHER = 'иные иждивенцы (указать кто)'
}

export const enum EDisabilityGroupForVehicle {
  ONE = '1',
  TWO = '2',
  THREE = '3'
}

export const enum EWaterCarriage {
  BOAT = 'Катер',
  POWERBOAT = 'Моторная лодка',
  JET_SKI = 'Гидроцикл',
  OTHER = 'Иное водное транспортное средство'
}

export const enum EAirTransport {
  AIRPLANE = 'Самолет',
  HELICOPTER = 'Вертолет',
  BALLOON = 'Воздушный шар',
  AEROSTAT = 'Aэростат',
  OTHER = 'Иное воздушное транспортное средство'
}

export interface IStepEightPayload {
  is_any_transport: boolean
  is_land_transport: boolean
  is_land_transport_for_util: boolean
  land_ts_util_type: ELandVehicleForUtil | '-'
  land_ts_util_mark: string
  land_ts_util_model: string
  land_ts_util_year: string
  land_ts_util_number: string
  land_ts_util_base: EVehicleForUtilReason | '-'
  land_transports: {
    transport_type: ELandVehicleForUtil
    other_transport_type: string
    is_in_current_marriage: boolean
    transport_status: ETransportStatus
    mark: string
    model: string
    year: string
    number: string
    vin_number: string
    address: string
    avg_price: number
    is_only_income: boolean
    is_tax: boolean
    relation_type: EVehicleRelation | '-'
    is_license: boolean
    license_type: ELandVehicleLicense | '-'
    other_license_type: string
    license_date: string
    license_number: string
    license_region: string
    is_registered: boolean
    used_for: ELandVehicleUsage | '-'
    used_for_other: string
    fullname: string
    dob: string
    disability_group: EDisabilityGroupForVehicle | '-'
    is_only_ts_for_disability: boolean
    is_any_mortgage: boolean
    is_any_borrower: boolean
    borrower_status: EPledgeOwner | '-'
    inn: string
    borrower_name: string
    borrower_address: string
    is_vital: boolean
    full_inn_address: string
  }[]
  land_util_transports: {
    land_ts_util_type: ELandVehicleForUtil | '-'
    land_ts_util_mark: string
    land_ts_util_model: string
    land_ts_util_year: string
    land_ts_util_number: string
    land_ts_util_base: EVehicleForUtilReason | '-'
  }[]
  is_water_transport: boolean
  is_water_transport_for_util: boolean
  water_ts_util_type: EWaterCarriage | '-'
  water_ts_util_mark: string
  water_ts_util_model: string
  water_ts_util_year: string
  water_ts_util_number: string
  water_ts_util_base: EVehicleForUtilReason | '-'
  water_transports: {
    transport_type: EWaterCarriage
    other_transport_type: string
    transport_status: ETransportStatus
    is_in_current_marriage: boolean
    mark: string
    model: string
    year: string
    number: string
    address: string
    avg_price: number
    is_only_income: boolean
    is_any_mortgage: boolean
    is_any_borrower: boolean
    borrower_status: EPledgeOwner | '-'
    inn: string
    borrower_name: string
    borrower_address: string
    is_tax: boolean
    relation_type: EVehicleRelation | '-'
    full_inn_address: string
  }[]
  water_util_transports: {
    water_ts_util_type: EWaterCarriage | '-'
    water_ts_util_mark: string
    water_ts_util_model: string
    water_ts_util_year: string
    water_ts_util_number: string
    water_ts_util_base: EVehicleForUtilReason | '-'
  }[]
  is_air_transport: boolean
  is_air_transport_for_util: boolean
  air_ts_util_type: EAirTransport | '-'
  air_ts_util_mark: string
  air_ts_util_model: string
  air_ts_util_year: string
  air_ts_util_number: string
  air_ts_util_base: EVehicleForUtilReason | '-'
  air_transports: {
    transport_type: EAirTransport
    other_transport_type: string
    transport_status: ETransportStatus
    is_in_current_marriage: boolean
    mark: string
    model: string
    year: string
    number: string
    address: string
    avg_price: number
    is_only_income: boolean
    is_any_mortgage: boolean
    is_any_borrower: boolean
    borrower_status: EPledgeOwner | '-'
    inn: string
    borrower_name: string
    borrower_address: string
    is_tax: boolean
    relation_type: EVehicleRelation | '-'
    full_inn_address: string
  }[]
  air_util_transports: {
    air_ts_util_type: EAirTransport | '-'
    air_ts_util_mark: string
    air_ts_util_model: string
    air_ts_util_year: string
    air_ts_util_number: string
    air_ts_util_base: EVehicleForUtilReason | '-'
  }[]
}

interface IStepNinePayload {
  is_gun: boolean
  guns: { gun_type: EGunType; is_lost: boolean; avg_sum: number}[]
}

export const enum EPropertyTypeStepTen {
  HOUSE = 'Жилой дом/дача',
  FLAT = 'Квартира',
  APARTAMENTS = 'Апартаменты',
  LAND_PLOT = 'Земельный участок',
  GARAGE = 'Гараж',
  COMMERCIAL = 'Коммерческая недвижимость',
  OTHER = 'Иное недвижимое имущество'
}

export const enum EPropertyStatus {
  OWNED = 'В собственности',
  LONG_TERM_LEASE = 'В долгосрочной аренде'
}

export const enum EPropertyOwnTypeStepTen {
  SHARE = 'Долевая',
  INDIVIDUAL = 'Индивидуальная',
  MUTUAL = 'Совместная'
}

export const enum EPropertyPurpose {
  SOCIAL = 'Социальная',
  COMMERCIAL = 'Коммерческая'
}

export const enum EPropertyOwningReason {
  BUY_SELL = 'Договор купли-продажи',
  RENT = 'Договор аренды',
  EXCHANGE = 'Договор мены',
  PRIVATIZATION = 'Приватизация',
  GIVING = 'Договор дарения',
  OTHER_DEAL = 'Иная сделка об отчуждении этого имущества',
  OTHER_CONTRACT = 'Иной тип договора (указать что)',
  FEDERAL_GIFT_AGREEMENT = 'Договор дарения в виде федеральной льготы',
  INHERITANCE = 'Наследство'
}

export const enum EPropertyRentOwner {
  FIZ = 'Физическое лицо',
  ENTITY = 'Юридическое лицо',
  STATE = 'Государство'
}

export const enum ERentDuration {
  LESS_THAN_ONE_YEAR = 'менее 1 года',
  MORE_THAN_ONE_YEAR = 'более 1 года'
}

interface IStepTenPayload {
  is_property_owner: boolean
  properties: {
    property_type: EPropertyTypeStepTen
    is_only_place: boolean
    who_owns: EPropertyStatus
    own_type: EPropertyOwnTypeStepTen | '-'
    share_amount: string
    avg_sum: number
    address: string
    square: number
    property_aim: EPropertyPurpose | '-'
    ownership_basis: EPropertyOwningReason | '-'
    ownership_other: string
    is_any_mortgage: boolean
    is_any_borrower: boolean
    borrower_status: EPledgeOwner | '-'
    inn: string
    borrower_name: string
    borrower_address: string
    rent_type: EPropertyRentOwner | '-'
    rent_duration: ERentDuration | '-'
    full_inn_address: string
    org_address: string
    is_in_current_marriage: boolean
  }[]
}

interface IStepElevenPayload {
  is_intellectual_property: boolean
  properties: {
    property_name: string
    avg_sum: number
    is_in_pledge: boolean
    pledge_owner: EPledgeOwner | '-'
    inn_or_name: string
    fullname: string
    address: string
    org_address: string
  }[]
}

export const enum ESecurityType {
  STOCK = 'Акции',
  BONDS = 'Облигации',
  INVESTMENT_SHARE = 'Инвестиционный пай',
  OTHER = 'Иное'
}

export const enum ESecurityStatus {
  DIGITAL = 'Цифровая',
  PHYSICAL = 'Бумажная'
}

interface IStepTwelvePayload {
  is_securities: boolean
  securities: {
    type: ESecurityType
    type_other: string
    creator: string
    count: number
    per_price: number
    status: ESecurityStatus
    is_in_pledge: boolean
    pledge_owner: EPledgeOwner | '-'
    inn_or_name: string
    name: string
    address: string
    full_inn_address: string
    org_address: string
    creator_full_address: string
    is_in_current_marriage: boolean
  }[]
}

interface IStepThirteenPayload {
  is_bank_deposit: boolean
  is_bank_account: boolean
  accounts: {
    name: string
    amount: number
    full_inn_address: string
    org_address: string
  }[]
  deposits: {
    name: string
    amount: number
    full_inn_address: string
    org_address: string
  }[]
}

interface IStepFourteenPayload {
  is_cash_credit: boolean
  amount: number
}

export const enum EJewelryType {
  JEWELRY = 'Драгоценности',
  DECORATIONS = 'Ювелирные украшения',
  PERSONAL_ITEMS = 'Ценные предметы личного пользования',
  ART_OBJECTS = 'Предметы искусства',
}

interface IStepFifteenPayload {
  is_jewelry: boolean
  jewelries: {
    type: EJewelryType
    address: string
    avg_sum: number
    is_in_pledge: boolean
    pledge_owner: EPledgeOwner | '-'
    inn_or_name: string
    name: string
    pledge_owner_address: string
    full_inn_address: string
    is_in_current_marriage: boolean
  }[]
}

interface IStepSixteenPayload {
  is_professional_property: boolean
  properties: {
    type: string
    address: string
    avg_sum: number
    is_in_pledge: boolean
    pledge_owner: EPledgeOwner | '-'
    inn_or_name: string
    name: string
    pledge_owner_address: string
    full_inn_address: string
  }[]
}

export const enum ELegalFormTypeSevenStep {
  OOO = 'ООО',
  PAO = 'ПАО',
  AO = 'АО',
  PEASANT_FARMING = 'Крестьянско-фермерское хозяйство',
  OTHER = 'иная правовая форма'
}

export const enum ELegalFormBasis {
  MEMORANDUM_OF_ASSOCIATION = 'Учредительный договор',
  PRIVATIZATION = 'Приватизация',
  PURCHASE = 'Покупка',
  EXCHANGE = 'Мена',
  GIVING = 'Дарение',
  INHERITANCE = 'Наследование',
  OTHER = 'Иное'
}

export const enum ELegalFormCapitalType {
  STATUTORY = 'Уставный',
  FOLDING = 'Складочный',
  SHARE = 'Паевый'
}

interface IStepSeventeenPayload {
  is_founder: boolean
  legal_forms: {
    legal_form: ELegalFormTypeSevenStep
    other_legal_form: string
    inn: string
    share_amount: string
    action_base: ELegalFormBasis | '-'
    action_base_other: string
    capital_type: ELegalFormCapitalType | '-'
    avg_sum: number
    actions_number: string
    action_price: number
    is_encumbrances: boolean
    pledge_owner: EPledgeOwner | '-'
    pledge_owner_inn: string
    name: string
    pledge_owner_address: string
    full_inn_address: string
    org_address: string
    full_name_address: string
    is_in_current_marriage: boolean
  }[]
}

export const enum EDebtorType {
  FIZ = 'Физическое лицо',
  ENTITY_OR_FOP = 'Юридическое лицо/ Индивидуальный предприниматель',
  STATE = 'Государство'
}

export const enum EGovernmentType {
  IFNS = 'Налоговая инспекция (ИФНС)',
  OTHER = 'Иное'
}

interface IStepEighteenPayload {
  is_debtor: boolean
  debtors: {
    debtor: EDebtorType
    government_type: EGovernmentType | '-'
    name: string
    inn_or_name: string
    date: string
    address: string
    debt_sum: number
    has_requested_court: boolean
    has_requested_organs: boolean
    full_inn_address: string
    org_address: string
  }[]
}

export const enum EDealType {
  TRADE = 'Обмен',
  BUY_SELL = 'Купля-продажа',
  GIVING = 'Дарение'
}

export const enum EPropertyForm {
  NOT_REAL_ESTATE = 'Движимое',
  REAL_ESTATE = 'Недвижимое'
}

export const enum ETransportType {
  CAR = 'Легковой автомобиль',
  FREIGHT_CAR = 'Грузовой автомобиль',
  MOTOR_VEHICLE = 'Мототранспортное средство',
  TRACTOR = 'Трактор',
  TRACKED_TRANSPORT = 'Гусеничный транспорт',
  BOAT = 'Катер',
  POWERBOAT = 'Моторная лодка',
  JET_SKI = 'Гидроцикл',
  AIRPLANE = 'Самолет',
  HELICOPTER = 'Вертолет',
  BALLOON = 'Воздушный шар',
  AEROSTAT = 'Аэростат',
  OTHER = 'Иное'
}

export const enum EPropertyFigure {
  CAR = 'Наземное транспортное средство',
  WATER_TRANSPORT = 'Водные виды транспорта',
  AIR_TRANSPORT = 'Воздушные виды транспорта',
  SHARE_IN_A_LEGAL_ENTITY = 'Доля в юридическом лице',
  HOUSE = 'Жилой дом',
  DACHA = 'Дача',
  ROOM = 'Комната',
  FLAT = 'Квартира',
  APARTMENTS = 'Апартаменты',
  LAND_PLOT = 'Земельный участок',
  GARAGE = 'Гараж',
  COMMERCIAL = 'Коммерческая недвижимость',
  OTHER = 'Иное недвижимое имущество'
}

export const enum EPropertyOwnType {
  SHARE = 'Долевая',
  INDIVIDUAL = 'Индивидуальная'
}

export const enum EPropertyNewOwner {
  CLOSE_RELATIVE = 'Близкий родственник',
  NAMESAKE = 'Однофамилец',
  SAME_REGISTRATION_ADDRESS = 'Лицо, у которого совпадает адрес регистрации с вашим',
  NO_RELATIONSHIP = 'Нет связи по родству'
}

interface IStepNineteenPayload {
  is_deals: boolean
  deals: {
    type: EDealType
    property_type: EPropertyForm
    figure: EPropertyFigure
    other_figure: string
    transport: ETransportType | '-'
    transport_other: string
    mark: string
    model: string
    year: string
    is_on_pledge: boolean
    is_deal_with_creditor: boolean
    is_delay: boolean
    delay_after_time: string
    company_name: string
    deal_percent: string
    deal_amount: number
    deal_date: string
    is_deal_by_market: boolean
    property_form: EPropertyOwnType | '-'
    property_deal_amount: string
    property_address: string
    property_new_owner: EPropertyNewOwner | '-'
    is_only_property: boolean
    property_square: string
    full_inn_address: string
    org_address: string
  }[]
}

export const enum EStepTwentyDocumentType {
  MARRIAGE_CONTRACT = 'Брачный договор',
  COURT_DECISION = 'Решения суда о разделе имущества',
  PROPERTY_DIVISION_AGREEMENT = 'Соглашение о разделе имущества'
}

export const enum EStepTwentyTransportType {
  CAR = 'Легковой автомобиль',
  FREIGHT_CAR = 'Грузовой автомобиль',
  BUS = 'Автобус',
  MOTOR_VEHICLE = 'Мототранспортное средство',
  TRACTOR = 'Трактор',
  TRACKED_TRANSPORT = 'Гусеничный транспорт',
  OTHER = 'Иное'
}

export const enum EStepTwentyTransportUser {
  PARTNER = 'Для супруга(и)',
  MYSELF = 'Для меня',
  KIDS = 'Для детей',
  PARENTS = 'Для родителей',
  OTHER = 'Иные иждивенцы'
}

export const enum EStepTwentyAirTransport {
  AIRPLANE = 'Самолет',
  HELICOPTER = 'Вертолет',
  BALLOON = 'Воздушный шар',
  AEROSTAT = 'Аэростат',
  OTHER = 'Иное'
}

export const enum EStepTwentyWaterTransport {
  BOAT = 'Катер',
  POWERBOAT = 'Моторная лодка',
  JET_SKI = 'Гидроцикл',
  OTHER = 'Иное'
}

interface IStepTwentyPayload {
  widow: {
    has_heredity: boolean
    has_property_in_rf: boolean
    is_property_purchased_in_marriage: boolean
    is_document: boolean
    document_type: EStepTwentyDocumentType | '-'
    has_land_ts: boolean
    has_water_ts: boolean
    has_air_ts: boolean
    has_property: boolean
    has_intellectual_property: boolean
    has_deals_last_3_years: boolean
    is_any_info: boolean
    info: string
  }
  marriage: {
    has_property_in_rf: boolean
    is_document: boolean
    document_type: EStepTwentyDocumentType | '-'
    has_land_ts: boolean
    land_ts: {
      transport: EStepTwentyTransportType
      transport_other: string
      mark: string
      model: string
      year: string
      is_lost: boolean
      is_on_pledge: boolean
      is_co_borowwer: boolean
      is_in_federal_reestr: boolean
      is_only_ts: boolean
      ts_user: EStepTwentyTransportUser | '-'
      other_ts_user: string
      is_vital: boolean
      user_name: string
      user_dob: string
      disability_group: Exclude<EDisabilityGroupForVehicle, EDisabilityGroupForVehicle.ONE> | '-'
      avg_price: number
    }[]
    has_air_ts: boolean
    air_ts: {
      transport: EStepTwentyAirTransport
      transport_other: string
      mark: string
      model: string
      year: string
      is_lost: boolean
      is_on_pledge: boolean
      is_co_borowwer: boolean
      avg_price: number
    }[]
    has_water_ts: boolean
    water_ts: {
      transport: EStepTwentyWaterTransport
      transport_other: string
      mark: string
      model: string
      year: string
      is_lost: boolean
      is_on_pledge: boolean
      is_co_borowwer: boolean
      avg_price: number
    }[]
  }
}

export const enum EGunTypeForPartner {
  SPORT = 'Спортивное',
  GAS = 'Газовое',
  TRAUMATIC = 'Травматическое',
  HUNTING = 'Охотничье'
}

interface IStepTwentyOnePayload {
  has_gun: boolean
  guns: {
    type: EGunTypeForPartner
    is_lost: boolean
  }[]
}

export const enum EPropertyType {
  COMMERCIAL = 'Коммерческая ннедвижимость',
  FLAT = 'Квартира',
  APARTMENTS = 'Апартаменты',
  HOUSE = 'Жилой дом/Дача',
  LAND_PLOT = 'Земельный участок',
  GARAGE = 'Гараж',
  OTHER = 'Иное недвижимое имущество'
}

export const enum EPropertyOwnBase {
  LEASE_CONTRACT = 'Договор аренды',
  OTHER_CONTRACT = 'Иной тип договора'
}

interface IStepTwentyTwoPayload {
  has_property: boolean
  properties: {
    type: EPropertyType
    status: EPropertyStatus
    own_base: EPropertyOwnBase | '-'
    other_own_base: string
    rent_duration: string
    own_type: EPropertyOwnType | '-'
    share_percent: string
    abg_sum: number
    is_on_pledge: boolean
    is_co_borrower: boolean
  }[]
}

interface IStepTwentyThreePayload {
  is_intellectual_property: boolean
  properties: {
    name: string
    avg_amount: number
  }[]
}

interface IStepTwentyFourPayload {
  is_any_bank_account: boolean
  is_any_bank_account_in_this_marriage: boolean
}

export const enum ELegalFormType {
  OOO = 'ООО',
  PAO = 'ПАО',
  AO = 'АО',
  PEASANT_FARMING = 'Крестьянско-фермерское хозяйство',
  OTHER = 'Иная коммерческая организация'
}

interface IStepTwentyFivePayload {
  is_share_in_legal_entity: boolean
  legal_entities: {
    type: ELegalFormType
    type_other: string
    name_inn: string
    full_inn_address: string
    org_address: string
  }[]
}

export const enum EPropertyTypeStepTwentySix {
  LAND_TRANSPORT = 'Наземное транспортное средство',
  WATER_CARRIAGE = 'Водные виды транспорта',
  AIR_TRANSPORT = 'Воздушные виды транспорта',
  SHARE_IN_A_LEGAL_ENTITY = 'Доля в юридическом лице',
  HOUSE = 'Жилой дом',
  FLAT = 'Квартира',
  APARTAMENTS = 'Апартаменты',
  LAND_PLOT = 'Земельный участок',
  GARAGE = 'Гараж',
  COMMERCIAL = 'Коммерческая недвижимость'
}

interface IStepTwentySixPayload {
  is_deal_for_property: boolean
  deals: {
    type: EDealType
    property_type: EPropertyForm
    figure: EPropertyTypeStepTwentySix
    transport: ETransportType | '-'
    transport_other: string
    mark: string
    model: string
    year: string
    deal_sum: number
    own_type: EPropertyOwnType | '-'
    share_percent: string
    avg_sum: number
    inn_or_name: string
    full_inn_address: string
    org_address: string
  }[]
}

export interface IFormPayload {
  step1?: IStepOnePayload
  step2?: IStepTwoPayload
  step3?: IStepThreePayload
  step4?: IStepFourPayload
  step5?: IStepFIvePayload
  step6?: IStepSixPayload
  step7?: IStepSevenPayload
  step8?: IStepEightPayload
  step9?: IStepNinePayload
  step10?: IStepTenPayload
  step11?: IStepElevenPayload
  step12?: IStepTwelvePayload
  step13?: IStepThirteenPayload
  step14?: IStepFourteenPayload
  step15?: IStepFifteenPayload
  step16?: IStepSixteenPayload
  step17?: IStepSeventeenPayload
  step18?: IStepEighteenPayload
  step19?: IStepNineteenPayload
  step20?: IStepTwentyPayload
  step21?: IStepTwentyOnePayload
  step22?: IStepTwentyTwoPayload
  step23?: IStepTwentyThreePayload
  step24?: IStepTwentyFourPayload
  step25?: IStepTwentyFivePayload
  step26?: IStepTwentySixPayload
  triggers?: number[]
}

export interface IDadataAddress {
  region: TNullable<string>
  street: TNullable<string>
  settlement: TNullable<string>
  city: TNullable<string>
  block: TNullable<string>
  flat: TNullable<string>
  house: TNullable<string>
  value: string
}

export interface IDadataBank {
  address: string
  value: string
  inn: string
  name: string
}

export interface IDadataOrganization {
  address: string
  inn: string
  value: string
}

export const enum EDadataType {
  ADDRESS = 1,
  ORGANIZATION,
  BANK
}

export const enum EDadataTypeExtended {
  ORGANIZATION_INN_ADDRESS = 4,
  ORGANIZATION_ADDRESS,
}
