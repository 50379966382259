import type { uuid } from '@/core/utils/shared'

export enum EComponentSize {
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large'
}

export enum EComponentType {
  INFO = 'info',
  PRIMARY = 'primary',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger'
}

export enum EPopoverPlacement {
  TOP = 'top',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  BOTTOM = 'bottom',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
  LEFT = 'left',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end',
  RIGHT = 'right',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end'
}

export enum EFormLabelPlacement {
  TOP = 'top',
  RIGHT = 'right',
  LEFT = 'left'
}

export type TNullable<T> = T | null

export type TUndef<T> = T | undefined

export interface INotification {
  id: ReturnType<typeof uuid>
  code: number
  message: string
}
