export const enum ERole {
  USER = 'USER',
  LAWYER = 'LAWYER'
}

export interface IRegisterUserPayload {
  name: string
  email: string
  phone: string
}

export interface ILoginPayload {
  username: string
  password: string
}

export interface ITokens {
  access_token: string
  refresh_token: string
}
